import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import toOrgUrl from '../lib/toOrgUrl';

const useOrgs = () => {
  const orgs = useSelector((state) => state?.orgStore?.orgs, shallowEqual);
  const isLoading = useSelector((state) => state?.orgStore?.isLoading);
  const memoedOrgs = useMemo(() => (
    orgs
      ? Object.values(orgs).map((org) => ({
        ...org,
        isLoading,
        toUrl: (path) => toOrgUrl(org, path),
      }))
      : []
  ), [isLoading, orgs]);

  return memoedOrgs;
};

export default useOrgs;
